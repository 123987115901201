"use client";

import cn from "clsx";

import { useUI } from "@/common/contexts/ui.context";
import CartIcon from "@/common/icons/cart-icon";
import { headerTestIds } from "@/common/layout/header/test-ids";
import { useCart } from "@/features/basket/hooks/use-cart";

export default function CartButton({ text }: { text?: string }) {
  const { openCart } = useUI();
  const { data: cart } = useCart();

  const cartLineItemsLength = cart?.lineItems?.length || 0;
  return (
    <button
      className="relative flex h-auto shrink-0 items-center justify-center focus:outline-none "
      onClick={openCart}
      data-testid={headerTestIds.basketButton}
      aria-label="cart-button"
    >
      <CartIcon />
      <span
        id="no-of-basket-items"
        className={cn(
          "absolute -top-2.5 left-2 flex min-h-[16px] min-w-[16px] items-center justify-center rounded-full p-0.5 text-[10px] font-bold leading-none text-white xl:-top-3 xl:left-3  xl:min-h-[20px] xl:min-w-[20px]",
          {
            "bg-grey-80": !cartLineItemsLength,
            "bg-success": cartLineItemsLength,
          },
        )}
      >
        {cartLineItemsLength}
      </span>
      <span className="hidden cursor-pointer pl-3 text-sm font-semibold transition-all duration-100 ease-in-out lg:block lg:font-normal xl:text-base">
        {text}
      </span>
    </button>
  );
}
