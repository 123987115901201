import { LineItem } from "@commercetools/platform-sdk";
import { CUSTOM_SKU } from "@wttb/product-configurator";
import cn from "clsx";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";

import { Link } from "@/common/components/link";
import { useUI } from "@/common/contexts/ui.context";
import DeleteIcon from "@/common/icons/delete-icon";
import EditIcon from "@/common/icons/edit-icon";
import { getCartItemName } from "@/features/basket/helpers/get-cart-item-name";
import { useRemoveCartItemMutation } from "@/features/basket/hooks/use-remove-cart-item";
import { BespokeBadge } from "@/features/bespoke/components/bespoke-badge";
import { useFormattedPrice } from "@/features/product/hooks/use-price";
import { ROUTES } from "@/features/store/settings/routes";

type CartItemProps = {
  cartId: string;
  cartVersion: number;
  item: LineItem;
};

export function CartItem({ cartId, cartVersion, item }: CartItemProps) {
  const t = useTranslations("common");
  const router = useRouter();
  const { closeCart } = useUI();
  const { mutateAsync: removeCartItem, isLoading } = useRemoveCartItemMutation();

  const totalPrice = useFormattedPrice(item.totalPrice);
  const taxedPrice = useFormattedPrice(item.taxedPrice?.totalGross);

  const isVatExempt = item.taxedPrice?.totalTax?.centAmount === 0;
  const imageUrl = item?.variant?.images?.[0]?.url ?? null;

  const isCustomProduct = item?.variant?.sku === CUSTOM_SKU;

  const handleRemoveCartItem = async () => {
    await removeCartItem({ cartId, cartVersion, lineItemId: item?.id });
    toast(t("toast-cart-item-removed"));
  };

  const productEditURL = `${ROUTES.PRODUCT}/${item?.productSlug?.["en-GB"]}/?updateKey=${item?.id}`;
  const handleEditCartItem = () => {
    closeCart();
    router.push(productEditURL);
  };

  return (
    <div
      className={cn(
        "border-grey-40 group relative flex h-auto w-full justify-start border-b bg-white py-6",
        { "pointer-events-none !opacity-50": isLoading },
      )}
    >
      <div className="bg-grey-10 mr-4 flex size-28 shrink-0 cursor-pointer overflow-hidden rounded-lg">
        <Image
          src={imageUrl || "/assets/placeholder/cart-item.svg"}
          width={112}
          height={112}
          loading="eager"
          alt={getCartItemName(item) || "Product Image"}
          className="bg-grey-30 object-cover"
          onClick={handleEditCartItem}
          sizes="112px"
        />
      </div>

      <div className="text-grey -mt-1 flex w-full flex-col justify-between overflow-hidden">
        <div>
          <div className="flex justify-between space-x-2">
            <div>
              <h2
                className="mb-0.5 cursor-pointer text-base font-semibold leading-tight"
                onClick={handleEditCartItem}
              >
                {getCartItemName(item)}
              </h2>
              <div className="text-sm">
                {t("short-text-quantity")}: {item.quantity}
              </div>
            </div>
            <div className="flex space-x-2">
              <BespokeBadge sku={item?.variant?.sku || ""} className="h-fit" />
              {!isCustomProduct && (
                <Link href={productEditURL} onClick={closeCart} className="size-8">
                  <EditIcon />
                </Link>
              )}
              {isLoading ? (
                <FaSpinner className="mt-1.5 inline-block w-8 animate-spin text-xl" />
              ) : (
                <button disabled={isLoading} className="size-8" onClick={handleRemoveCartItem}>
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold leading-normal">{totalPrice}</div>
          <div className="text-xs">
            {!isVatExempt ? `${taxedPrice} ${t("text-inc-vat")}` : t("text-vat-exempt")}
          </div>
        </div>
      </div>
    </div>
  );
}
