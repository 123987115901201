"use client";

import cn from "clsx";
import React from "react";
import ContentLoader from "react-content-loader";

type HeaderLoaderProps = {
  height?: number;
  className?: string;
  style?: Record<string, string>;
};

export function HeaderLoader({ height = 20, className = "", style = {} }: HeaderLoaderProps) {
  return (
    <ContentLoader
      speed={2}
      height={height}
      className={cn("block h-full", className)}
      backgroundColor="#EBECEC"
      style={style}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height={`${height}`} />
    </ContentLoader>
  );
}
