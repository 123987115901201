const EditIcon = ({ strokeColor = "#5B6771", width = "32px", height = "32px" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m5.336 26.667 6.553-1.542 14.329-14.328a1.542 1.542 0 0 0 0-2.18l-2.83-2.831a1.542 1.542 0 0 0-2.182 0L6.878 20.114l-1.542 6.553zm21.328-.001h-8"
      />
    </svg>
  );
};

export default EditIcon;
