"use client";

import cn from "clsx";
import { useTranslations } from "next-intl";
import { IoClose } from "react-icons/io5";

import { Link } from "@/common/components/link";
import { Scrollbar } from "@/common/components/scrollbar";
import { useUI } from "@/common/contexts/ui.context";
import { useCart } from "@/features/basket/hooks/use-cart";
import { basketTestIds } from "@/features/basket/test-ids";
import { sumPrices, useFormattedPrice } from "@/features/product/hooks/use-price";
import { ROUTES } from "@/features/store/settings/routes";
import { CartItem } from "./cart-item";
import { EmptyCart } from "./empty-cart";

export function Cart() {
  const t = useTranslations("common");
  const { closeCart } = useUI();
  const { data: cart } = useCart();
  const cartTotal = useFormattedPrice(
    sumPrices(...(cart?.lineItems.map((item) => item.totalPrice) || [])),
  );
  const taxedTotal = useFormattedPrice(
    sumPrices(...(cart?.lineItems.map((item) => item.taxedPrice?.totalGross) || [])),
  );
  const isVatExempt = cart?.taxedPrice?.totalTax?.centAmount === 0;

  const totalCartItems = cart?.lineItems?.length || 0;

  return (
    <div className="flex size-full flex-col justify-between">
      <div className="border-grey-40 relative flex min-h-[96px] w-full items-center justify-between border-b py-0.5 pl-5 md:pl-7">
        <h2 className="text-grey m-0 text-lg font-bold">
          {t.rich("drawer-basket-headline", {
            highlight: (text: React.ReactNode) => <span className="text-success">{text}</span>,
            count: totalCartItems,
          })}
        </h2>
        <button
          className="text-grey hover:bg-grey-100 mr-4 flex size-8 items-center justify-center rounded-full bg-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.08)] hover:text-white focus:outline-none"
          onClick={closeCart}
          aria-label="close"
        >
          <IoClose className="text-2xl text-inherit" />
        </button>
      </div>
      {cart?.lineItems?.length ? (
        <Scrollbar className="cart-scrollbar w-full grow">
          <div className="w-full px-6">
            {cart.lineItems.map((item) => (
              <CartItem
                data-testid={basketTestIds.basketDrawerItem}
                cartId={cart.id}
                cartVersion={cart.version}
                item={item}
                key={item.id}
              />
            ))}
            <button
              onClick={closeCart}
              className="background-none my-6 border-none text-base underline"
            >
              {t("text-continue-shopping")}
            </button>
          </div>
        </Scrollbar>
      ) : (
        <div className="flex flex-col items-center justify-center px-5 pb-5 pt-8 md:px-7">
          <EmptyCart />
          <h3 className="pt-8 text-lg font-bold">{t("text-empty-cart")}</h3>
        </div>
      )}

      <div className="bg-success-light flex p-6" onClick={closeCart}>
        <Link
          href={cart?.lineItems?.length ? ROUTES.BASKET : "/"}
          data-testid={basketTestIds.rightPanelGoToCheckoutLink}
          className={cn(
            "flex h-14 w-full items-center justify-center rounded-[28px]  px-8 text-white transition duration-300 focus:outline-none",
            cart?.lineItems?.length
              ? "bg-success hover:bg-success-dark"
              : "bg-grey-60 hover:bg-grey-60 cursor-not-allowed",
          )}
        >
          <span className="w-full py-0.5 pr-5 text-base font-semibold leading-tight">
            {t("text-proceed-to-checkout")}
          </span>
          <span className="ml-auto flex shrink-0 py-0.5">
            <span className="border-l border-white pr-4" />
            <div className="py-0.5 text-right">
              <div className="mb-0.5 text-lg font-semibold leading-none">{cartTotal}</div>
              {!isVatExempt && (
                <div className="text-xs leading-none">{`${taxedTotal} ${t("text-inc-vat")}`}</div>
              )}
            </div>
          </span>
        </Link>
      </div>
    </div>
  );
}
