"use client";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import dynamic from "next/dynamic";
import { Fragment, PropsWithChildren } from "react";

import { Link } from "@/common/components/link";
import { useUI } from "@/common/contexts/ui.context";
import HomeIcon from "@/common/icons/home-icon";
import MenuIcon from "@/common/icons/menu-icon";
import SearchIcon from "@/common/icons/search-icon";
import UserIcon from "@/common/icons/user-icon";

const CartButton = dynamic(() => import("@/features/basket/components/cart-button"), {
  ssr: false,
});

export function MobileNavigation({ children }: PropsWithChildren) {
  const { openSidebar, closeSidebar, displaySidebar, openSearch } = useUI();
  return (
    <>
      <div className="shadow-bottomNavigation body-font text-grey-100 fixed bottom-0 z-10 flex h-14 w-full items-center justify-between bg-white px-4 sm:h-16 md:px-8 lg:hidden">
        <button
          aria-label="Menu"
          className="menuBtn flex shrink-0 flex-col items-center justify-center outline-none focus:outline-none"
          onClick={openSidebar}
        >
          <MenuIcon />
        </button>
        <button
          className="relative flex h-auto shrink-0 items-center justify-center focus:outline-none"
          onClick={openSearch}
          aria-label="search-button"
        >
          <SearchIcon />
        </button>
        <Link href="/" className="shrink-0">
          <HomeIcon />
        </Link>
        <CartButton />
        <UserIcon />
      </div>
      <Transition show={displaySidebar}>
        <Dialog onClose={closeSidebar} className="relative z-[999999]">
          <TransitionChild
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0 backdrop-blur-none"
            enterTo="opacity-100 backdrop-blur-[.5px]"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="opacity-100 backdrop-blur-[.5px]"
            leaveTo="opacity-0 backdrop-blur-none"
          >
            <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className="border-grey-30 fixed inset-y-0 left-0 flex h-full w-11/12 max-w-[512px] flex-col border-l bg-white p-0 text-black">
              {children}
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </Transition>
    </>
  );
}
