import { sendGTMEvent } from "@next/third-parties/google";
import { useEffect, useState } from "react";

export interface TransactionData {
  transactionId: string;
  transactionAffiliation: string;
  transactionTotal: string;
  transactionSubTotal: string;
  transactionTax: string;
  transactionShipping: {
    shippingMethodName: string;
    price: string;
    discountedPrice: string;
  }[];
  order_value: string;
  order_id: string;
  ecomm_pagetype: string;
  event: string;
  enhanced_conversion_data: { email?: string };
  client_id?: string;
  discount_code: { name?: string; code?: string }[] | undefined;
  loyalty_discount?: string;
  transactionProducts: {
    id: string;
    sku: string | undefined;
    name: string;
    category: string;
    price: string;
    total: string;
    quantity: number;
    isBespoke: boolean;
  }[];
  productCategories?: { name: string; metaTitle: string }[];
}

interface LoginData {
  logged_in: boolean;
  user_id?: string;
}

export const useDataLayer = () => {
  const [dataLayerObject, setDataLayerObject] = useState<TransactionData | LoginData>();

  useEffect(() => {
    dataLayerObject && sendEventToGTM();
  }, [dataLayerObject]);

  const sendEventToGTM = () => {
    sendGTMEvent(dataLayerObject!);
  };

  return { setDataLayerObject, sendEventToGTM };
};
