"use client";

import { usePathname } from "next/navigation";
import { HTMLProps } from "react";

export function ExitPreviewModeLink(props: HTMLProps<HTMLAnchorElement>) {
  const pathname = usePathname();

  return (
    <div className="bg-warning-light fixed bottom-5 left-1/2 z-50 -ml-32 inline-block w-64 rounded-full px-10 py-4 text-center shadow-md">
      Draft mode is on!{" "}
      <a href={`/api/draft?disable=true&redirect=${pathname}`} {...props}>
        Exit
      </a>
    </div>
  );
}
