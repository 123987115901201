"use client";

import cn from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/overlayscrollbars.css";

type ScrollbarProps = {
  options?: any;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export function Scrollbar({ options, children, style, className, ...props }: ScrollbarProps) {
  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: { autoHide: "scroll" },
        ...(options ? options : {}),
      }}
      className={cn("os-theme-thin", className)}
      style={style}
      defer
      {...props}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
}
