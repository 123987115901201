const DeleteIcon = ({ strokeColor = "#5B6771", width = "32px", height = "32px" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8 9.334 1.282 14.58c.137 1.558 1.455 2.753 3.036 2.753h7.364c1.58 0 2.9-1.195 3.036-2.753L24 9.334m-12 0v-1.09c0-1.608 1.592-2.91 3.556-2.91h.888c1.964 0 3.556 1.302 3.556 2.91v1.09m-14.664 0h21.333"
      />
    </svg>
  );
};

export default DeleteIcon;
