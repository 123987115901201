import algoliasearch from "algoliasearch";

import { config } from "@/config";

export const initializeAlgolia = () => {
  const searchClient = algoliasearch(
    config.NEXT_PUBLIC_ALGOLIA_APP_ID!,
    config.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY!,
    {
      // headers: {
      //     "X-Algolia-UserToken": 'usertoken123456' refer doc for ideal values
      // }
    },
  );

  return searchClient;
};
