export const basketTestIds = {
  basketItemPrice: "basket-item-price",
  basketItemName: "basket-item-name",
  basketItemQuantity: "basket-item-quantity",
  basketItemDeliveryOptions: "basket-item-delivery-options",
  basketSummary: "basket-summary",
  basketCheckoutSecurelyBtn: "basket-checkout-securely-btn",
  basketDrawerItem: "basket-drawer-item",
  rightPanelGoToCheckoutLink: "right-panel-go-to-checkout-link",
};
