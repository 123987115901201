"use client";

import cn from "clsx";
import { useSession } from "next-auth/react";
import { useTranslations } from "next-intl";
import React, { useEffect } from "react";

import { Link } from "@/common/components/link";
import { useUI } from "@/common/contexts/ui.context";
import { headerTestIds } from "@/common/layout/header/test-ids";
import { ROUTES } from "@/features/store/settings/routes";
import { getLoginDataLayer } from "@/features/store/utils/data-layer-variables";
import { useDataLayer } from "@/features/store/utils/use-data-layer";
import UserIcon from "../../icons/user-icon";

export default function AuthMenu({
  linkClassName,
  iconClassName,
  onClick,
}: {
  linkClassName?: string;
  iconClassName?: string;
  onClick?: () => void;
}) {
  const t = useTranslations();
  const { setModalConfig } = useUI();
  const { data: session, status } = useSession();
  const { setDataLayerObject } = useDataLayer();

  useEffect(() => {
    if (status === "authenticated" && !session.isAnonymous) {
      setDataLayerObject(getLoginDataLayer(true, session));
    }
  }, [status, session, setDataLayerObject]);

  function handleLogin() {
    return setModalConfig({ view: "LOGIN_VIEW", autoOpen: true });
  }

  return (
    <div className="align-center flex gap-x-4 ">
      <UserIcon className={iconClassName} />
      {status === "authenticated" && !session.isAnonymous ? (
        <Link
          data-testid={headerTestIds.myAccountLink}
          href={ROUTES.ACCOUNT}
          className={cn("text-grey-90 text-sm xl:text-base", linkClassName)}
          id="signedInText"
          onClick={onClick}
        >
          {t("menu.menu-my-account")}
        </Link>
      ) : (
        <button
          data-testid={headerTestIds.signInButton}
          className={cn("text-grey-90 text-sm focus:outline-none xl:text-base", linkClassName)}
          onClick={handleLogin}
        >
          {t("common.text-sign-in")}
        </button>
      )}
    </div>
  );
}
