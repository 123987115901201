"use client";

import SearchIcon from "@/common/icons/search-icon";

import "./search.css";

import cn from "clsx";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { useSearchBox } from "react-instantsearch";

import { Scrollbar } from "@/common/components/scrollbar";
import { useUI } from "@/common/contexts/ui.context";
import { CustomHit } from "./custom-hit";
import { EmptyQueryBoundary } from "./error-boundaries";

/* Displays search box on header and opens algolia search window from search.tsx. */
export function AlgoliaSearchForm({ className }: { className?: string }) {
  const t = useTranslations("forms");
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const { closeSearch, displaySearch, openSearch } = useUI();

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  const defaultClassName = cn("absolute lg:relative lg:block lg:w-full", {
    "opacity-100 z-30 xs:block xs:w-11/12": displaySearch,
    "xs:hidden": !displaySearch,
  });

  return (
    <>
      <div
        className={cn("overlay", {
          open: displaySearch,
        })}
        role="button"
        onClick={() => {
          closeSearch();
          setQuery("");
          setInputValue("");
        }}
      ></div>
      <div className={cn("mr-auto", className ?? defaultClassName)}>
        <form
          className="bg-grey-10 focus-within:shadow-search relative overflow-hidden rounded-full focus-within:rounded-lg focus-within:bg-white lg:block"
          noValidate
          role="search"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onReset={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setQuery("");
          }}
        >
          <label htmlFor="algolia-search" className="flex items-center">
            <span className="absolute left-0 top-0 flex h-full w-12 shrink-0 cursor-pointer items-center justify-center focus:outline-none md:w-14">
              <SearchIcon className="text-grey-90 size-[18px]" />
            </span>
            <input
              id="algolia-search"
              className="placeholder:text-grey-80 focus:border-berry-90 h-14 w-full appearance-none rounded-full bg-transparent pl-14 pr-4 text-base outline-none focus:rounded-lg focus:border-[1.5px]"
              aria-label="Search"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              value={inputValue}
              onChange={(event) => {
                setQuery(event.currentTarget.value);
              }}
              onClick={openSearch}
              placeholder={t("placeholder-search")}
            />
          </label>
        </form>
        <div className="max-h-64vh absolute z-50 mt-2 flex h-auto w-full flex-col overflow-hidden rounded-md bg-white lg:max-h-[550px]">
          <Scrollbar className="os-host-flexbox">
            <div className="h-full">
              <EmptyQueryBoundary fallback={null}>
                <CustomHit setQuery={setQuery} closeSearch={closeSearch} />
              </EmptyQueryBoundary>
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
}
