import { signOut, useSession } from "next-auth/react";
import { useEffect } from "react";

export const AuthTokenValidator = ({ children }: { children: React.ReactNode }) => {
  const { data } = useSession();

  useEffect(() => {
    if (data?.terminate) {
      signOut({ redirect: true, callbackUrl: "/" }); // Force sign out to hopefully resolve error
    }
  }, [data?.terminate]);

  return <>{children}</>;
};
