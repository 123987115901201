"use client";

import { setUser } from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { useMemo } from "react";

// Client Sentry manager which add customer id to sentry scope
export const SentryUserManager = () => {
  const session = useSession();

  useMemo(() => {
    if (session.data?.customerId) {
      setUser({
        id: session.data.customerId,
      });
      return;
    }
    setUser(null);
  }, [session]);

  return null;
};
