"use client";

import { Transition, TransitionChild } from "@headlessui/react";
import cn from "clsx";
import dynamic from "next/dynamic";
import React, { ReactNode, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";

import {
  CtfEntry,
  getPath,
  isTypeExternalLink,
  isTypeNavFirstLevel,
  TypeNavFirstLevelSkeleton,
  TypeNavigationSkeleton,
  TypeNavSecondLevelSkeleton,
  TypePageSkeleton,
} from "@/clients/contentful";
import { Link } from "@/common/components/link";
import { OptionalLink } from "@/common/components/optional-link";
import { Scrollbar } from "@/common/components/scrollbar";
import { useUI } from "@/common/contexts/ui.context";
import { HeaderLoader } from "@/common/layout/header/header-loader";
import {
  ParentMenuHeader,
  ParentMenuItem,
} from "@/common/layout/mobile-navigation/mobile-menu-item";

const AlgoliaSearch = dynamic(
  () =>
    import("@/features/store/components/search/algolia-search").then((mod) => mod.AlgoliaSearch),
  {
    ssr: false,
    loading: () => <HeaderLoader style={{ width: "100%" }} height={56} className="mr-auto !h-14" />,
  },
);

type LevelNavigation = CtfEntry<
  TypeNavigationSkeleton | TypeNavFirstLevelSkeleton | TypeNavSecondLevelSkeleton
>[];

type MobileMenuLevelsProps = {
  nav: CtfEntry<TypeNavigationSkeleton>;
};

type LevelProps = {
  onLevelChange: (navigation: LevelNavigation) => void;
  onBack: () => void;
  navigation: LevelNavigation;
  isForward: boolean;
};

export function MobileMenuLevels({ nav }: MobileMenuLevelsProps) {
  const [navigation, setNavigation] = useState<LevelNavigation>([nav]);
  const [isForward, setIsForward] = useState<boolean>(true);

  const handleLevelChange = (newNavigation: LevelNavigation) => {
    setNavigation(newNavigation);
    setIsForward(true);
  };
  const handleBack = () => {
    setNavigation((prevState) => prevState.slice(0, -1));
    setIsForward(false);
  };

  return (
    <div className="ml-6 mr-10">
      {navigation.length === 1 && (
        <MainLevel
          isForward={isForward}
          navigation={navigation}
          onLevelChange={handleLevelChange}
          onBack={handleBack}
        />
      )}
      {navigation.length === 2 && (
        <FirstLevel
          isForward={isForward}
          navigation={navigation}
          onLevelChange={handleLevelChange}
          onBack={handleBack}
        />
      )}
      {navigation.length === 3 && (
        <SecondLevel
          isForward={isForward}
          navigation={navigation}
          onLevelChange={handleLevelChange}
          onBack={handleBack}
        />
      )}
    </div>
  );
}

function MainLevel({ isForward, navigation, onLevelChange }: LevelProps) {
  const { closeSidebar } = useUI();
  const nav = navigation[navigation.length - 1] as CtfEntry<TypeNavigationSkeleton>;
  if (!nav?.fields.mobileNavigation) {
    throw new Error("Failed to load mobile menu");
  }

  return (
    <Transition appear>
      <TransitionChild
        enter="transition-all ease-in-out duration-300"
        enterTo="translate-x-0"
        enterFrom={isForward ? "" : "translate-x-full"}
      >
        <div>
          <AlgoliaSearch className="relative z-50 mr-6 block w-full" />

          <Scrollbar className="menu-scrollbar">
            <div className="border-grey-20 my-7 flex flex-col gap-y-1 border-b pb-5">
              {nav.fields.mobileNavigation.map(
                (menu) =>
                  menu && (
                    <ParentMenuItem
                      key={menu.sys.id}
                      entry={menu}
                      onClick={() => onLevelChange([...navigation, menu])}
                    />
                  ),
              )}
            </div>
          </Scrollbar>

          <div className="flex flex-col">
            {nav.fields.headerLinks?.map((item) => {
              if (!item) return;

              if (isTypeNavFirstLevel(item)) {
                return (
                  <ParentMenuItem
                    key={item?.sys.id}
                    entry={item}
                    className="!text-base"
                    onClick={() => onLevelChange([...navigation, item])}
                  />
                );
              }

              const pageEntry = item as CtfEntry<TypePageSkeleton>;

              return (
                <OptionalLink
                  key={item.sys.id}
                  href={getPath(pageEntry)}
                  className="text-grey-90 relative flex items-center px-4 py-0 text-base font-normal hover:text-black"
                  onClick={closeSidebar}
                >
                  {pageEntry.fields.menuTitle || pageEntry.fields.title}
                </OptionalLink>
              );
            })}
          </div>
        </div>
      </TransitionChild>
    </Transition>
  );
}

function FirstLevel({ isForward, navigation, onLevelChange, onBack }: LevelProps) {
  const nav = navigation[navigation.length - 1] as CtfEntry<TypeNavFirstLevelSkeleton>;
  return (
    <Transition appear>
      <TransitionChild
        enter="transition-all ease-in-out duration-300"
        enterTo="translate-x-0"
        enterFrom={isForward ? "-translate-x-full" : "translate-x-full"}
      >
        <div className="ml-3">
          <IoIosArrowRoundBack className="text-grey-90 cursor-pointer" size={40} onClick={onBack} />
          <LevelItems name={nav.fields.name}>
            {nav.fields.items?.map(
              (menu) =>
                menu && (
                  <ParentMenuItem
                    entry={menu}
                    onClick={() => onLevelChange([...navigation, menu])}
                  />
                ),
            )}
          </LevelItems>
        </div>
      </TransitionChild>
    </Transition>
  );
}

function SecondLevel({ isForward, navigation, onBack }: LevelProps) {
  const nav = navigation[navigation.length - 1] as CtfEntry<TypeNavSecondLevelSkeleton>;
  const parent = navigation[navigation.length - 2] as CtfEntry<TypeNavFirstLevelSkeleton>;
  const { closeSidebar } = useUI();
  const categoryLink = nav.fields.targetPage && getPath(nav.fields.targetPage);
  return (
    <Transition appear>
      <TransitionChild
        enter="transition-all ease-in-out duration-300"
        enterTo="translate-x-0"
        enterFrom={isForward ? "-translate-x-full" : "translate-x-full"}
      >
        <div className="ml-3">
          <div className="flex cursor-pointer items-center" onClick={onBack}>
            <IoIosArrowRoundBack className="text-grey-90" size={40} />
            <span className="ml-4 mt-0.5 text-base leading-6">{parent.fields.name}</span>
          </div>
          <LevelItems name={nav.fields.name} categoryLink={categoryLink} boldName>
            {nav.fields.links?.map((menu) => {
              if (!menu) return;

              const item = menu as CtfEntry<TypePageSkeleton>;

              if (isTypeExternalLink(item)) {
                return (
                  <a
                    key={item.sys.id}
                    href={item.fields.url}
                    target="_blank"
                    className="hover:bg-grey-30"
                    onClick={closeSidebar}
                  >
                    <ParentMenuHeader name={item.fields.menuTitle || item.fields.name} />
                  </a>
                );
              }

              return (
                <Link
                  key={item.sys.id}
                  id={item.fields.slug}
                  href={getPath(item)!}
                  className="hover:bg-grey-30"
                  onClick={closeSidebar}
                >
                  <ParentMenuHeader name={item.fields.menuTitle || item.fields.title} />
                </Link>
              );
            })}
          </LevelItems>
        </div>
      </TransitionChild>
    </Transition>
  );
}

function LevelItems({
  name,
  categoryLink,
  boldName = false,
  children,
}: {
  name: string;
  categoryLink?: string;
  boldName?: boolean;
  children: ReactNode;
}) {
  const { closeSidebar } = useUI();
  return (
    <div className="mt-3.5 flex flex-col gap-y-1">
      <OptionalLink href={categoryLink} onClick={() => categoryLink && closeSidebar()}>
        <ParentMenuHeader name={name} className={cn("!px-2.5", { "font-semibold": boldName })} />
      </OptionalLink>
      <Scrollbar className="menu-scrollbar">
        <div className="pl-2.5">{children}</div>
      </Scrollbar>
    </div>
  );
}
