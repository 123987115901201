"use client";

import { IoClose } from "react-icons/io5";

import { useUI } from "@/common/contexts/ui.context";

export function MobileMenuCloseButton() {
  const { closeSidebar } = useUI();

  return (
    <button
      className="text-grey-40 shadow-closeSidebar flex items-center justify-center rounded-full p-1.5 text-2xl transition-opacity hover:opacity-60 focus:outline-none"
      onClick={closeSidebar}
      aria-label="close"
    >
      <IoClose className="text-grey-90 mt-1 md:mt-0.5" />
    </button>
  );
}
