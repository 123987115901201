"use client";

import { ContentfulLivePreview } from "@contentful/live-preview";
import { useEffect } from "react";

import { ExitPreviewModeLink } from "@/features/store/components/exit-preview-mode-link";

export const LivePreviewSubscriber = ({ isEnabled }: { isEnabled: boolean }) => {
  useEffect(() => {
    if (isEnabled) {
      ContentfulLivePreview.init({
        locale: "en-GB",
        debugMode: true,
        enableLiveUpdates: true,
      });

      ContentfulLivePreview.subscribe("save", {
        callback: async (arg) => {
          if (Array.isArray(arg)) {
            const entryIds = arg.map((x) => x.sys.id).join(",");
            await fetch(`/api/revalidate?tags=${entryIds}`);
          } else {
            await fetch(`/api/revalidate?tag=${arg.sys.id}`);
          }
          const pathname = window.location.pathname;
          // Added direct redirect to API route to have redirect to revalidated Page Path
          window.location.href = `/api/revalidate?pathname=${pathname}`;
        },
      });
    }
  }, [isEnabled]);

  return isEnabled && <ExitPreviewModeLink className="underline" />;
};
