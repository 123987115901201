"use client";

import "./search.css";

import { Configure, InstantSearch } from "react-instantsearch";

import { initializeAlgolia } from "@/clients/algolia/algoliaApis";
import { config } from "@/config";
import { AlgoliaSearchForm } from "./search-form";

const searchClient = initializeAlgolia();

/* Displays search box on header and opens algolia search window from search.tsx. */
export function AlgoliaSearch({ className }: { className?: string }) {
  return (
    <InstantSearch //connects to alogolia app
      searchClient={searchClient}
      indexName={config.NEXT_PUBLIC_ALGOLIA_INDEX}
      future={{ preserveSharedStateOnUnmount: true }}
      insights={true} //enables sending events to Algolia.
    >
      <Configure hitsPerPage={15} />
      <AlgoliaSearchForm className={className} />
    </InstantSearch>
  );
}
