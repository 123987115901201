//few more errorboundaries to be added for algolia

import { useInstantSearch } from "react-instantsearch";

function EmptyQueryBoundary({ children, fallback }: any) {
  const { indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return fallback;
  }

  return children;
}

export { EmptyQueryBoundary };
