import Image from "next/image";

import { Link } from "@/common/components/link";
import wttbLogo from "../../../../public/assets/images/logo.svg";

type LogoProps = {
  height?: number;
  onClick?: () => void;
};
export function Logo({ height = 24, onClick }: LogoProps) {
  return (
    <Link href="/" className="inline-flex focus:outline-none" onClick={onClick}>
      <Image
        priority
        loading="eager"
        src={wttbLogo}
        alt="WTTB"
        className="w-auto"
        style={{ height: height ?? "auto" }}
      />
    </Link>
  );
}
