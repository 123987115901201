"use client";

import { useSession } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

import { createViewedPageEvent } from "@/clients/klaviyo/events";
import { config } from "@/config";
import { useMe } from "@/features/account/hooks/use-me";

export function RouteChangeListener() {
  const pathname = usePathname();
  const session = useSession();
  const { data: customer } = useMe(session.data);

  useEffect(() => {
    if (pathname && customer?.email) {
      createViewedPageEvent(config.NEXT_PUBLIC_BASE_URL + pathname, customer.email);
    }
  }, [pathname, customer?.email]);

  return <></>;
}
