// The purpose of this file is to have all the data layer variables at one place.

import { Customer, DiscountCodeInfo, LineItem, Order, Shipping } from "@commercetools/platform-sdk";
import { Session } from "next-auth";

import { formatPrice } from "@/features/product/hooks/use-price";
import { TransactionData } from "@/features/store/utils/use-data-layer";

export const getPurchaseEventDataLayer = (
  order: Order,
  customer: Customer | null | undefined,
): TransactionData => {
  const client_id = document.cookie.match(/_ga=(.+?);/)
    ? document.cookie
        .match(/_ga=(.+?);/)?.[1]
        .toString()
        .split(".")
        .slice(-2)
        .join(".")
    : "Undefined";

  return {
    transactionId: order?.id,
    transactionAffiliation: "Where The Trade Buys",
    transactionTotal: formatPrice(order?.totalPrice),
    transactionSubTotal: formatPrice(order?.totalPrice),
    transactionTax: formatPrice(order?.taxedPrice?.totalTax),
    transactionShipping: order?.shipping.map((shippingData: Shipping) => ({
      shippingMethodName: shippingData.shippingInfo.shippingMethodName,
      price: formatPrice(shippingData.shippingInfo.price),
      discountedPrice: formatPrice(shippingData.shippingInfo.discountedPrice?.value),
    })),
    order_value: formatPrice(order?.totalPrice),
    order_id: order?.id,
    ecomm_pagetype: "purchase",
    event: "purchase",
    enhanced_conversion_data: {
      email: customer?.email,
    },
    client_id: client_id,
    discount_code: order?.discountCodes?.map((discountCodeInfo: DiscountCodeInfo) => ({
      name: discountCodeInfo.discountCode.obj?.name?.["en-GB"],
      code: discountCodeInfo.discountCode.obj?.code,
    })),
    transactionProducts: order?.lineItems?.map((orderItem: LineItem) => ({
      id: orderItem.id,
      sku: orderItem.variant?.sku,
      name: orderItem.name["en-GB"],
      category: "N/A",
      price: formatPrice(orderItem.price.value),
      total: formatPrice(orderItem.totalPrice),
      quantity: orderItem.quantity,
      isBespoke: !!order.quote,
    })),
    productCategories: [],
  };
};

export const getLoginDataLayer = (logged_in: boolean, session: Session) => {
  return { logged_in: logged_in, user_id: session.customerId };
};
