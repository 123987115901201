"use client";

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider, signOut } from "next-auth/react";
import { ReactNode, useState } from "react";

import { AuthTokenValidator } from "./auth-token-validator";

const invalidTokenHandler = (error: unknown) => {
  // Error from commerce tools
  if ((error as Error)?.message === "invalid_token") {
    signOut({ redirect: true, callbackUrl: "/" });
  } else {
    throw error;
  }
};

export function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: invalidTokenHandler,
        }),
        mutationCache: new MutationCache({
          onError: invalidTokenHandler,
        }),
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <AuthTokenValidator>{children}</AuthTokenValidator>
      </SessionProvider>
    </QueryClientProvider>
  );
}
