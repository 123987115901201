import { MyCartUpdate } from "@commercetools/platform-sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Session } from "next-auth";
import { getSession, signIn, useSession } from "next-auth/react";
import PQueue from "p-queue";
import { toast } from "react-toastify";

import { createApiClient } from "@/clients/commercetools";
import { updateTOSessionWithPromotions as updateTalonSessionWithPromotions } from "@/clients/talon-one/cart";
import { fetchCart } from "@/features/basket/hooks/use-cart";

export interface RemoveCartInputType {
  cartVersion: number;
  cartId: string;
  lineItemId: string;
}

const removeCartItemQueue = new PQueue({ concurrency: 1 });

async function processRemoveCartItem(session: Session | null, lineItemId: string) {
  const api = createApiClient(session!.accessToken);
  const cart = await fetchCart(session);

  if (!cart) {
    throw new Error("Cart not found");
  }

  const body: MyCartUpdate = {
    version: cart.version,
    actions: [
      {
        action: "removeLineItem",
        lineItemId: lineItemId,
      },
    ],
  };
  const newCart = await api.me().carts().withId({ ID: cart.id }).post({ body }).execute();
  await updateTalonSessionWithPromotions({ cart: newCart.body });
}

async function removeCartItem(session: Session | null, input: RemoveCartInputType) {
  if (!session) {
    await signIn("guest", { redirect: false });
    session = await getSession();
  }

  await removeCartItemQueue.add(() => processRemoveCartItem(session, input.lineItemId));
  await removeCartItemQueue.onIdle();
}

export function useRemoveCartItemMutation() {
  const { data: session } = useSession();
  const queryClient = useQueryClient();
  return useMutation((input: RemoveCartInputType) => removeCartItem(session, input), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CART"] });
    },
    onError: (data: Error) => {
      toast.error("Error removing cart item: " + data.message);
    },
  });
}
