"use client";

import { CUSTOM_SKU } from "@wttb/product-configurator";
import cn from "clsx";
import { useTranslations } from "next-intl";

type BespokeBadgeProps = {
  sku: string;
  className?: string;
};

export function BespokeBadge({ sku, className }: BespokeBadgeProps) {
  const t = useTranslations("common");
  const isCustomProduct = sku === CUSTOM_SKU;

  return (
    <div
      className={cn(
        "inline-block whitespace-nowrap rounded-full px-3 py-1 text-white bg-grey-80",
        className,
        {
          hidden: !isCustomProduct,
        },
      )}
    >
      <span className="text-base font-normal">{t("text-bespoke")}</span>
    </div>
  );
}
