import { Hits } from "react-instantsearch";

import { SearchProduct } from "../search-product";

type CustomHitProps = {
  setQuery: (query: string) => void;
  closeSearch: () => void;
};

export function CustomHit({ setQuery, closeSearch }: CustomHitProps) {
  return (
    <Hits
      hitComponent={({ hit }) => {
        return (
          <div
            className=" border-grey-20 relative border-b p-5"
            onClick={() => {
              setQuery("");
              closeSearch();
            }}
          >
            <SearchProduct item={hit} />
          </div>
        );
      }}
    />
  );
}
