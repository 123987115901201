"use client";

import React from "react";

import { useUI } from "@/common/contexts/ui.context";
import AuthMenu from "@/common/layout/header/auth-menu";
import { MobileMenuCloseButton } from "@/common/layout/mobile-navigation/mobile-menu-close-button";
import { Logo } from "@/features/store/components/logo";

export function MobileMenuHeader() {
  const { closeSidebar } = useUI();
  return (
    <>
      <div className="border-grey-20 relative flex w-full shrink-0 items-center justify-between border-b p-4">
        <Logo onClick={closeSidebar} />
        <MobileMenuCloseButton />
      </div>

      <div className="flex justify-end px-10 py-6">
        <AuthMenu linkClassName="!text-base" iconClassName="text-grey-90" onClick={closeSidebar} />
      </div>
    </>
  );
}
