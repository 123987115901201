"use client";

export const NavItem = ({ itemText, className }: { itemText: string; className: string }) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const parentElement = (event.target as HTMLElement).closest(".menuItem");
    if (parentElement) {
      parentElement.classList.remove("group");
      global.setTimeout(function () {
        parentElement.classList.add("group");
      }, 500);
    }
  };

  return (
    <span className={className} onClick={handleClick}>
      {itemText}
    </span>
  );
};
