"use client";

import React from "react";

import { useUI } from "@/common/contexts/ui.context";

export function MobileMenuButton() {
  const { openSidebar } = useUI();

  function handleMobileMenu() {
    return openSidebar();
  }

  return (
    <button
      aria-label="Menu"
      className="group hidden h-full shrink-0 flex-col items-center justify-center px-5 outline-none focus:outline-none md:flex lg:hidden 2xl:px-7"
      onClick={handleMobileMenu}
    >
      <span className="flex h-auto w-6 flex-col items-start justify-center space-y-1.5">
        <span className="bg-grey flex h-0.5 w-1/2 rounded-2xl transition-all duration-200 ease-in-out group-hover:w-full" />
        <span className="bg-grey flex h-0.5 w-full rounded-2xl transition-all duration-200 ease-in-out group-hover:w-2/3" />
        <span className="bg-grey flex h-0.5 w-3/4 rounded-2xl transition-all duration-200 ease-in-out group-hover:w-full" />
      </span>
    </button>
  );
}
