import cn from "clsx";
import React, { CSSProperties } from "react";
import { IoIosArrowForward } from "react-icons/io";

import {
  CtfEntry,
  getPath,
  isTypeNavFirstLevel,
  isTypeNavSecondLevel,
  TypeNavFirstLevelSkeleton,
  TypeNavSecondLevelSkeleton,
} from "@/clients/contentful";
import { OptionalLink } from "@/common/components/optional-link";
import { useUI } from "@/common/contexts/ui.context";

export function ParentMenuItem({
  entry,
  onClick,
  className = "",
}: {
  entry: CtfEntry<TypeNavFirstLevelSkeleton | TypeNavSecondLevelSkeleton>;
  onClick: () => void;
  className?: string;
}) {
  const { closeSidebar } = useUI();

  const name = isTypeNavFirstLevel(entry)
    ? entry.fields.name
    : isTypeNavSecondLevel(entry)
      ? entry.fields.name
      : "";
  const highlighted = isTypeNavFirstLevel(entry) && entry.fields.highlighted;
  const hasDropdown = isTypeNavFirstLevel(entry)
    ? !!entry.fields.items?.length
    : isTypeNavSecondLevel(entry)
      ? !!entry.fields.links?.length
      : false;

  const href =
    !hasDropdown && isTypeNavSecondLevel(entry) ? getPath(entry.fields.targetPage) : undefined;

  const style = highlighted
    ? {
        color: entry.fields.highlightTextColour ?? "#FFFFFF",
        backgroundColor: entry.fields.highlightBackgroundColour ?? "#5B6771",
      }
    : {};

  return (
    <OptionalLink href={href} onClick={() => href && closeSidebar()}>
      <div className="flex cursor-pointer items-center justify-between gap-x-2" onClick={onClick}>
        <ParentMenuHeader
          name={name}
          className={cn({ "rounded-lg": highlighted }, className)}
          style={style}
        />
        {hasDropdown && <IoIosArrowForward className="text-grey-60 min-w-4" />}
      </div>
    </OptionalLink>
  );
}

export function ParentMenuHeader({
  name,
  style = {},
  className,
}: {
  name: string;
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <div className={cn("text-grey-90 px-4 pb-2.5 pt-3 text-xl leading-5", className)} style={style}>
      {name}
    </div>
  );
}
